<template>
    <div>
        <div style="width:auto;margin:-30px auto auto auto" id="printme" >
            <v-row class="text-center" style="border: 1px solid #ccc;background:#FFF">
                <v-col cols="4" style="direction:rtl">
                    <div style="font-size:1.5em !important;">{{ settings.company_name }}</div>
                    <div style="font-size:1.5em !important;">جوال: {{settings.mobile}}</div>
                    <div style="font-size:1.5em !important;">{{settings.address}}</div>
                    <div style="font-size:1.2em !important;">الرقم الضريبي: {{settings.vat_number}}</div>
                </v-col>
                <v-col cols="4"></v-col>
                <v-col cols="4" style="direction:ltr">
                    <div style="font-size:1.2em !important;">{{settings.company_name_en}}</div>
                    <div style="font-size:1.5em !important;">جوال: {{settings.mobile}}</div>
                    <div style="font-size:1.5em !important;">{{settings.address_en}}</div>
                    <div style="font-size:1.2em !important;">VAT ID: {{settings.vat_number}}</div>
                </v-col>
            </v-row>
            <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                <v-col cols="4" style="font-size:1.5em !important;">
                    رقم الكرت: {{ this.cardid }}
                </v-col>
                <v-col cols="4" style="font-size:1.5em !important;">
                    كرت اصلاح
                        / Job Card
                </v-col>
                <v-col cols="4" style="font-size:1.5em !important;">
                    Date: {{ card.sdate }}
                </v-col>
            </v-row>
            <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                <b-table-simple style="margin:0">
                    <tbody>
                        <tr>
                            <th style="width:150px;">اسم العميل</th>
                            <td style="background:#fff">{{ Customer.name }}</td>
                            <th style="width:150px;">رقم الجوال</th>
                            <td style="background:#fff">{{ Customer.mobile }}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-row>
            <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                <b-table-simple style="margin:0">
                    <thead>
                        <tr>
                            <th >نوع السيارة</th>
                            <td style="background:#fff">{{ car.car_model }}</td>
                            <th>اللون</th>
                            <td style="background:#fff">{{ car.car_color }}</td>
                            <th>الموديل</th>
                            <td style="background:#fff">{{ car.car_madel_year }}</td>
                            <th>رقم اللوحة</th>
                            <td style="background:#fff">{{ car.plate_number }}</td>
                        </tr>
                    </thead>
                </b-table-simple>
            </v-row>
            <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                    <thead>
                        
                        <tr>
                            <th>تاريخ الاضافة<br>Added Date</th>
                            <th style="width:50%;">الوصف<br>Description</th>
                            <th>السعر<br> Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(serve,index) in serves" :key="index">
                            <td style="background:#fff">{{ serve.created_date }}</td>
                            <td style="background:#fff">{{ serve.description }}</td>
                            <td style="background:#fff">{{ serve.price }}</td>
                        </tr>
                        <tr>
                            <td style="color:green;font-weight:bold"></td>
                            <td style="color:green;font-weight:bold">المجموع</td>
                            <td style="color:green;font-weight:bold">{{ totals.stotal }}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-row>
            <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                    <b-table-simple style="margin:0">
                    <thead>
                        
                        <tr>
                            <th>تاريخ الاضافة<br>Added Date</th>
                            <th>رقم القطعة<br>Item Code</th>
                            <th style="width:25%;">اسم القطعة<br>Item Name</th>
                            <th>الكمية<br>Qty</th>
                            <th>السعر<br>Item Price</th>
                            <th>الاجمالي<br>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(part,index) in parts" :key="index">
                            <td style="background:#fff">{{ part.created_date }}</td>
                            <td style="background:#fff">{{ part.itemid }}</td>
                            <td style="background:#fff">{{ part.description }}</td>
                            <td style="background:#fff">{{ part.qty }}</td>
                            <td style="background:#fff">{{ part.price }}</td>
                            <td style="background:#fff">{{ part.total }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td style="color:green;font-weight:bold">المجموع</td>
                            <td style="color:green;font-weight:bold">{{ totals.pprice }}</td>
                            <td style="color:green;font-weight:bold">{{ totals.ptotal }}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <b-table-simple style="margin:0">
                        <tbody>
                            <tr>
                                <td style="background:#fff;white-space: pre-wrap;font-size:1.5em !important;">{{ settings.tafweed }}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                    <v-row>
                        <span style="float:right;margin-right:5px;">{{ settings.terms }}</span>
                    </v-row>
                </v-col>
                <v-col cols="6">
                    <PrintAdditional />
                </v-col>
            </v-row>
            <v-row class="text-center" style="border: 1px solid #ccc;background:#eee">
                <b-table-simple style="margin:0">
                    <tbody>
                        <tr>
                            <th style="width:150px;">اسم العميل</th>
                            <td style="background:#fff;width:30%;">{{ Customer.name }}</td>
                            <th style="width:150px;">رقم الجوال</th>
                            <td style="background:#fff">{{ Customer.mobile }}</td>
                            <th style="width:150px;">التوقيع</th>
                            <td style="background:#fff;width:30%;"></td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-row>
        </div>
    </div>
</template>


<script>
import axios from 'axios'
import PrintAdditional from '@/components/printAdditional.vue'
export default {
  components: { PrintAdditional },
    
    data() {
        return {
            cardid: 0,
            card: {
                cardid: '',
                sdate: '',
                delivery: '',
                statusper: '',
                max: 100,
                clicked: [],
            },
            Customer: {
                name: '',
                mobile: '',
                email: '',
                city: '',
                Address: ' ',
            },
            car:{
                plate_number: '',
                car_madel_year: '',
                car_color: '',
                car_model: '',
            },
            settings:[

            ],
            parts:[],
            serves:[],
            totals:{
                pprice:0,
                ptotal:0,
                sprice:0,
                stotal:0,
            }
        }
    },
    created() {
        this.cardid = this.$route.params.cardid;
        this.getCard();
        this.getSettings(); 
        this.getparts();
        this.getService();
        setTimeout(()=>{this.calcTotal();},1500);
        setTimeout(()=>{this.printPage();},2500);
    },
    methods: {
        calcTotal(){
            const parts = this.parts;
            const serves = this.serves;
            
            for(let i=0;i<parts.length;i++){
                this.totals.pprice = +this.totals.pprice + +parts[i].price;
                this.totals.ptotal = +this.totals.ptotal + +parts[i].total;
            }
            
            for(let i=0;i<serves.length;i++){
                this.totals.stotal = +this.totals.stotal + +serves[i].total;
            }
            
        },
        getparts(){
            const formdata = new FormData();
            formdata.append('type','getCItem');
            formdata.append('defBranch',localStorage.getItem('defBranch'));
            formdata.append('financeYear',localStorage.getItem('financeYear'));
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);
            formdata.append('data[type]','parts');
            axios.post(
                this.$SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                this.parts = res.results.data.results;
            });
            
        },
        getService(){
            const formdata = new FormData();
            formdata.append('type','getCItem');
            formdata.append('defBranch',localStorage.getItem('defBranch'));
            formdata.append('financeYear',localStorage.getItem('financeYear'));
            formdata.append('auth',this.$cookies.get(this.$COOKIEPhase));
            formdata.append('data[cardid]',this.cardid);
            formdata.append('data[type]','service');
            axios.post(
                this.$SAMCOTEC.r_path, formdata
            ).then((response) => {
                const res = response.data;
                this.serves = res.results.data.results;
                // // console.log(this.serves);
            });
        },
        getCard(){
            this.cardid = this.$route.params.cardid;
            let post = new FormData();
            post.append('type' , 'getCards');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            const auth = this.$cookies.get(this.$COOKIEPhase);
            post.append('auth',auth);
            post.append('data[cardid]',this.cardid)
            axios.post(
                this.$store.state.SAMCOTEC.r_path,
                post
            ).then((response) => {
                const res = response.data;
                this.Customer.name = res.results.data[0].customer;
                this.Customer.mobile = res.results.data[0].mobile;
                //this.Customer.email = res.results.data[0].mobile;
                //this.Customer.city = res.results.data[0].city;
                //this.Customer.address = res.results.data[0].address;
                //// // console.log("HI THERE",this.card.clicked);
                this.card.cardid = res.results.data[0].id;
                this.card.sdate = res.results.data[0].date_in;
                this.card.delivery = res.results.data[0].date_out;
                this.card.statusper = res.results.data[0].status;
                const cl = res.results.data[0].clicked;
                const clt = cl.split(',');
                for(let i = 0;i<clt.length;i++){ 
                this.card.clicked.push(parseInt(clt[i])); 
                }
                this.car.plate_number = res.results.data[0].plate_number;
                this.invoicedata = res.results.data[0];
                this.car.car_model = res.results.data[0].car_model;
                this.car.car_madel_year = res.results.data[0].car_madel_year;
                this.car.car_color = res.results.data[0].car_color;
                this.theclicked = this.card.clicked;
                this.getClicked("testing ",this.card.clicked);
            })
        },
        getClicked(clicked){
            this.card.clicked = clicked
        },
        printPage(){
            // document.getElementById("pageHeader").style.display = 'none !important;';
            // document.getElementById("Footer").style.display = 'none !important;';
            window.print();
            window.close();
        },
        getSettings(){
            const post = new FormData();
            post.append("type",'getSettings');
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$COOKIEPhase));
            post.append("data[all]",'all');
            axios.post(
                this.$SAMCOTEC.r_path,post
            ).then(
                (response) => {
                    const res = response.data;
                    this.settings = res.results.data;
                }
            )
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>
<style>
#printme *{
    direction:rtl;
    font-size:12px !important;
}
#printme{
    font-size:12px !important;
}
</style>